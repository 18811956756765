<template lang="pug">
.container
  canvas.canvas(ref='canvas' :style="{width: '100vw', height: '100vh'}")
</template>

<script>
import { onMounted, ref } from 'vue';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import * as THREE from 'three';
import * as SimplexNoise from 'simplex-noise';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';

import curveFrag from '../commands/particle-curve/curve.fs';
import curveVert from '../commands/particle-curve/curve.vs';
import { initBg } from './ParticleSpiral.vue';

const POINTS_NUM = 1300;
const LINE_NUM = 20;

export default {
  name: 'ParticleCurve',
  setup() {
    const canvas = ref(null);
    onMounted(() => {
      const { camera, scene, renderer, controls } = presetThree(canvas.value);

      let raf;

      const curveGroup = new THREE.Group();
      const curveObject = createCurve();
      curveGroup.rotation.set((Math.PI * 4) / 5, Math.PI / 4, Math.PI / 2);
      curveGroup.add(...curveObject);

      const moveGroup = new THREE.Group();
      moveGroup.add(curveGroup);
      // scene.add(moveGroup)

      const bg = initBg();
      scene.add(bg.mesh);
      scene.add(moveGroup);

      const cameraPos = [-13.8, 2.29, 12.49];
      const cameraRot = [-0.18, -0.82, -0.13];
      camera.position.set(...cameraPos);
      camera.rotation.set(...cameraRot);

      const composer = createBloomPass(scene, camera, renderer);
      const clock = new THREE.Clock();

      // const updateMouse = createMouseController(canvas, moveGroup)

      const gui = new GUI();
      // const position = {
      //   cameraX: cameraPos[0],
      //   cameraY: cameraPos[1],
      //   cameraZ: cameraPos[2],
      //   cameraRX: cameraRot[0],
      //   cameraRY: cameraRot[1],
      //   cameraRZ: cameraRot[2],
      //   lineColor: '#5B9EF7',
      // }

      // gui.add(position, 'cameraX', -40, 40).onChange((v) => {
      //   camera.position.x = v
      // })
      // gui.add(position, 'cameraY', -50, 50).onChange((v) => {
      //   camera.position.y = v
      // })
      // gui.add(position, 'cameraZ', -50, 50).onChange((v) => {
      //   camera.position.z = v
      // })
      // gui.add(position, 'cameraRX', -10, 10).onChange((v) => {
      //   camera.rotation.x = v
      // })
      // gui.add(position, 'cameraRY', -10, 10).onChange((v) => {
      //   camera.rotation.y = v
      // })
      // gui.add(position, 'cameraRZ', -10, 10).onChange((v) => {
      //   camera.rotation.z = v
      // })

      const params = {
        topLeft: '#070643',
        topRight: '#070643',
        bottomLeft: '#111111',
        bottomRight: '#111111',
        thinSpeed: 1,
        thinColor: '#5B9EF7',
        thickSpeed: 1,
        thickColor: '#5B9EF7',
      };

      gui.addColor(params, 'topLeft').onChange(() => {
        bg.mesh.material.uniforms.u_topLeft.value.set(params.topLeft);
      });
      gui.addColor(params, 'topRight').onChange(() => {
        bg.mesh.material.uniforms.u_topRight.value.set(params.topRight);
      });
      gui.addColor(params, 'bottomLeft').onChange(() => {
        bg.mesh.material.uniforms.u_bottomLeft.value.set(params.bottomLeft);
      });
      gui.addColor(params, 'bottomRight').onChange(() => {
        bg.mesh.material.uniforms.u_bottomRight.value.set(params.bottomRight);
      });
      gui.add(params, 'thinSpeed', 0, 10).onChange(() => {
        curveObject.forEach((curve) => {
          if (curve.userData.type === 'thin')
            curve.material.uniforms.speed.value = params.thinSpeed;
        });
      });
      gui.add(params, 'thickSpeed', 0, 10).onChange(() => {
        curveObject.forEach((curve) => {
          if (curve.userData.type === 'thick')
            curve.material.uniforms.speed.value = params.thickSpeed;
        });
      });
      gui.addColor(params, 'thinColor').onChange(() => {
        curveObject.forEach((curve) => {
          if (curve.userData.type === 'thin')
            curve.material.uniforms.lineColor.value.set(params.thinColor);
        });
      });
      gui.addColor(params, 'thickColor').onChange(() => {
        curveObject.forEach((curve) => {
          if (curve.userData.type === 'thick')
            curve.material.uniforms.lineColor.value.set(params.thickColor);
        });
      });

      const anime = () => {
        // console.log(camera.position, camera.rotation)
        // updateMouse()
        const delta = clock.getDelta();
        raf = requestAnimationFrame(anime);
        curveObject.forEach((curve) => {
          curve.material.uniforms.time.value += 0.2;
        });
        controls.update();
        renderer.render(scene, camera);
        composer.render(delta);
      };

      anime();
    });
    return {
      canvas,
    };
  },
};

function createBloomPass(scene, camera, renderer) {
  const renderPass = new RenderPass(scene, camera);
  const unrealBloomPass = new UnrealBloomPass(new THREE.Vector2(256, 256), 2, 0.5, 0.18);
  unrealBloomPass.renderToScreen = true;
  const composer = new EffectComposer(renderer);

  composer.addPass(renderPass);
  composer.addPass(unrealBloomPass);
  return composer;
}

function createCurve() {
  const noise3D = SimplexNoise.createNoise3D(() => 0.9013180713867854);

  const n = 0.0005;
  const deltaX = 0.001;

  const compute = (...[x, y, z]) => {
    return (...[s, c, d]) =>
      (noise3D(x + s * n, y + c * n, z + d * n) - noise3D(x - s * n, y - c * n, z - d * n)) /
      deltaX;
  };

  const computeCurl = (x, y, z) => {
    const pos = new THREE.Vector3();
    const calc = compute(x, y, z);

    pos.x = calc(0, 1, 0) - calc(0, 0, 1);
    pos.y = calc(0, 0, 1) - calc(1, 0, 0);
    pos.z = calc(1, 0, 0) - 2 * calc(0, 1, 0);

    return pos;
  };

  const getCurve = (pos) => {
    const pointsPos = [];
    const r = pos.clone();
    pointsPos.push(pos);

    for (let n = 0; n < POINTS_NUM; n++) {
      const o = computeCurl(r.x / 15, r.y / 15, r.z / 15);
      r.addScaledVector(o, 0.004);
      pointsPos.push(r.clone());
    }

    return pointsPos;
  };

  const getCurveMaterial = (dashed, lineOpacity) => {
    return new THREE.ShaderMaterial({
      uniforms: {
        time: {
          value: 0,
        },
        dashed: {
          value: dashed,
        },
        opacity: {
          value: 1,
        },
        lineOpacity: {
          value: lineOpacity,
        },
        lineColor: {
          value: new THREE.Color(0x5b9ef7),
        },
        speed: {
          value: 1,
        },
      },
      vertexShader: curveVert,
      fragmentShader: curveFrag,
      transparent: true,
    });
  };

  const getCurveGeometry = (factor = 0) => {
    const curvePoints = getCurve(
      new THREE.Vector3(factor / 10, -0.5 + Math.random(), -factor / 10),
    );
    const curve = new THREE.CatmullRomCurve3(curvePoints, false, 'catmullrom', 0.5);
    const curve0 = new THREE.TubeGeometry(curve, 200, 0.001, 8, false);
    const curve1 = new THREE.TubeGeometry(curve, 200, 0.006, 8, false);

    return [curve0, curve1];
  };

  const curveGroups = [];

  for (let i = 0; i < LINE_NUM; i++) {
    const curves = getCurveGeometry(i);

    const curveObject0 = new THREE.Mesh(curves[0], getCurveMaterial(10, 0.1));
    curveObject0.userData.type = 'thin';

    const curveObject1 = new THREE.Mesh(curves[1], getCurveMaterial(40, 0.05));
    curveObject1.userData.type = 'thick';
    curveGroups.push(curveObject0, curveObject1);
  }

  return curveGroups;
}

function presetThree(canvas) {
  const w = canvas.offsetWidth;
  const h = canvas.offsetHeight;

  const scene = new THREE.Scene();

  const camera = new THREE.PerspectiveCamera(50, w / h, 0.1, 1000);
  scene.add(camera);

  const renderer = new THREE.WebGLRenderer({
    antialias: true,
    canvas,
    alpha: true,
  });

  renderer.toneMapping = THREE.NoToneMapping;

  const controls = new OrbitControls(camera, renderer.domElement);
  controls.zoomSpeed = 0.01;

  renderer.setPixelRatio(2);
  renderer.setSize(w, h);

  window.addEventListener('resize', () => {
    const w = canvas.offsetWidth;
    const h = canvas.offsetHeight;
    camera.aspect = w / h;
    camera.updateProjectionMatrix();
    renderer.setPixelRatio(2);
    renderer.setSize(w, h);
  });

  return {
    camera,
    scene,
    renderer,
    controls,
  };
}
</script>
