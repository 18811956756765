<template lang="pug">
.stats.fixed
  span.fps(ref='stats') 0
.p-controller.absolute(ref='pController')
canvas.canvas.fixed(ref='canvas')
h2.text.fixed
  span CONNECT TO THE WORLD
  span AROUND YOU WITH 5G
</template>

<script>
import createREGL from 'regl';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import mat4 from 'gl-mat4';

window.__DEBUG__ = false;
import { addScrollListener } from '@zhinan-oppo/scroll-handle';

import vert from '@/shaders/particle-5g.vert';
import frag from '@/shaders/particle-5g.frag';

function getInitialPoints({ nRow, nCol, N, rMin, rMax }) {
  const positions = [];
  for (let i = 0; i < N; i += 1) {
    const row = i % nRow;
    const r = Math.sqrt(Math.random() * (rMax ** 2 - rMin ** 2) + rMin ** 2);
    // const r = rMax - (rMax - rMin) * Math.random();
    // const alpha = (Math.floor(Math.random() * 200) / 200) * (2 * Math.PI);
    const alpha = Math.random() * (2 * Math.PI);
    positions.push([r, alpha, row / nRow]);
  }
  return { positions, count: positions.length };
}

function getExplosionPoints(positions, { nRow, nCol, N, rMin, rMax }) {
  const res = [];
  for (let i = 0; i < positions.length; i += 1) {
    const r = rMin + (rMax - rMin) * Math.random() ** 2;
    const alpha = Math.random() * 2 * Math.PI;
    res.push([r, alpha, positions[i][2]]);
  }
  return { positions: res, count: res.length };
}

function createTrans({ p, max = 1, min = 0, T, onChange }) {
  const ctx = {
    p,
    min,
    max,
    startedAt: undefined,
    startedP: p,
    dest: undefined,
    change: (dest = max) => {
      if (dest !== ctx.dest) {
        ctx.startedAt = Date.now();
        ctx.startedP = ctx.p;
        ctx.dest = dest;
      }
      const dir = ctx.p < dest ? 1 : -1;

      window.cancelAnimationFrame(ctx.raf);
      ctx.raf = window.requestAnimationFrame(() => {
        const now = Date.now();
        const t = (now - ctx.startedAt) / T;
        ctx.p = ctx.startedP + (dest - ctx.startedP) * t;

        if ((dir > 0 && dest > ctx.p) || (dir < 0 && dest < ctx.p)) {
          ctx.change(dest);
        } else {
          ctx.p = dest;
          ctx.startedP = ctx.p;
          ctx.startedAt = undefined;
        }
        if (onChange) {
          onChange(ctx);
        }
      });
    },
  };
  return ctx;
}

const transState01 = createTrans({ p: 0, T: 500 });
const transEyeX = createTrans({ p: 0, min: -15, max: 15, T: 1500 });

export default {
  name: 'Stars',
  setup() {
    const canvas = ref(null);
    const img = ref(null);
    const stats = ref(null);
    const pController = ref(null);

    /** @type {ReturnType<typeof createREGL>} */
    let regl;

    const Z_DEPTH = 500;
    const R = 50;
    const R_MIN = 0.98;
    const R_MIN_EXPLOSION = 0.7;
    const ROW_N = 5;
    const ROW_GAP = Z_DEPTH / ROW_N;
    const COL_N = 4000;

    const options = {
      nRow: ROW_N,
      nCol: COL_N,
      N: ROW_N * COL_N,
      rMin: R_MIN,
      rMax: 1,
      rowGap: ROW_GAP / Z_DEPTH,
    };
    const initialPoints = getInitialPoints(options);
    const explosionPoints = getExplosionPoints(initialPoints.positions, {
      ...options,
      rMin: R_MIN_EXPLOSION,
    });

    const animation = { rMin: R_MIN, time: [0, 0], state: 0 };
    const timeUniforms = {};
    animation.time.forEach((_, i) => {
      timeUniforms[`time[${i}]`] = (_, { time }) => time[i];
    });

    onMounted(() => {
      const onResize = () => {
        canvas.value.width = window.innerWidth * 2;
        canvas.value.height = window.innerHeight * 2;
      };
      window.addEventListener('resize', onResize);
      onResize();

      regl = createREGL({ canvas: canvas.value });

      const draw = regl({
        vert: vert.replace('${Z_SEGMENTS}', ROW_N.toFixed(1)),
        frag: frag.replace('${Z_SEGMENTS}', ROW_N.toFixed(1)),
        context: {
          projection: ({ viewportWidth, viewportHeight }) => {
            // const scale = viewportWidth > 1024 ? 2 : 1;
            // return mat4.ortho(
            //   [],
            //   -viewportWidth / scale,
            //   viewportWidth / scale,
            //   viewportHeight / scale,
            //   -viewportHeight / scale,
            //   0.01,
            //   10000,
            // );
            return mat4.perspective([], Math.PI / 6, viewportWidth / viewportHeight, 0.01, 1000);
          },
          eye: ({ tick }) => {
            const t = 0.001 * tick;
            // return [4, 0, 0];
            return [transEyeX.p, 0, 300];
            // return [3, 3000 * Math.cos(t), 3000 * Math.sin(t)];
          },
        },
        uniforms: {
          ...timeUniforms,
          projection: ({ projection }) => projection,
          model: mat4.identity([]),
          view: ({ eye }) => mat4.lookAt([], eye, [0, 0, 0], [0, 1, 0]),
          radius: R,
          rMax: 1,
          rMin: R_MIN,
          rMinExplosion: R_MIN_EXPLOSION,
          zDepth: Z_DEPTH,
          p: () => transState01.p,
        },
        attributes: {
          position: initialPoints.positions,
          explosionPos: explosionPoints.positions,
        },
        count: initialPoints.count,
        primitive: 'points',
        depth: { enable: false },
        blend: {
          enable: true,
          func: {
            srcRGB: 'src alpha',
            srcAlpha: 'src alpha',
            dstRGB: 'one minus src alpha',
            dstAlpha: 'one minus src alpha',
          },
        },
      });

      let perfTime;
      let c = 0;
      let lastTime = 0;
      const update = ({ time }) => {
        if (!perfTime) {
          perfTime = time;
        }
        if (!lastTime) {
          lastTime = time;
        }

        c += 1;
        if (c >= 60) {
          const fps = c / (time - perfTime);
          c = 0;
          perfTime = time;
          stats.value.textContent = fps;
        }

        animation.time[animation.state] += time - lastTime;

        draw({ time: animation.time });

        lastTime = time;
      };
      const { cancel } = regl.frame((...args) => {
        try {
          update(...args);
        } catch (e) {
          console.error(e);
          cancel();
        }
      });

      addScrollListener(pController.value, {
        start: 'top',
        end: 'bottom',
        forceInViewBoundary: true,
        handlers: {
          onStateChange(ctx) {
            animation.state = ctx.state === 'inView' ? 1 : 0;
            if (animation.state === 1) {
              transState01.change(1);
            } else {
              transState01.change(0);
            }
          },
        },
      });
    });

    window.addEventListener(
      'mousemove',
      /** @param {MouseEvent} e */
      (e) => {
        const x = -(e.clientX / window.innerWidth - 0.5) * 2 * transEyeX.max;
        // transEyeX.change(x);
        transEyeX.p = x;
      },
    );

    onBeforeUnmount(() => regl?.destroy());

    return { canvas, img, stats, pController };
  },
};
</script>

<style lang="stylus">
body
  background #000
</style>

<style lang="stylus" scoped>
*
  box-sizing border-box
  color #fff
  margin 0

.canvas
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  cursor pointer
  background #000

  img
    display none

.stats
  z-index 2
  color #fff
  width 100%
  padding 0 10px
  display flex
  justify-content space-between
  color cyan

.fixed
  position fixed
  left 0
  top 0

.absolute
  position absolute
  left 0
  top 0

.p-controller
  width 100%
  height 100vh
  margin-top 10px

.text
  width 100%
  height 100%
  display flex
  flex-direction column
  align-items center
  justify-content center
  font-size 68px
  font-weight 750
  padding-top 30px
</style>
