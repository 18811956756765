<template lang="pug">
#container
</template>

<script>
import * as THREE from 'three';
import { onMounted } from 'vue';
import { ShaderMaterial } from 'three';

const DURATION = 1.3;

const getPoints = (progress, vertices, yDiff = 0.3) => {
  return vertices.map(
    (vertice) =>
      new THREE.Vector3(
        vertice.x,
        vertice.y + yDiff * Math.sin(progress * 2 * Math.PI + vertice.y * 2),
        vertice.z,
      ),
  );
};

const getMesh = (radius, opacity, size, yDiff) => {
  const pointMaterial = new ShaderMaterial({
    transparent: true,
    uniforms: {
      color1: {
        value: new THREE.Color(0x1291e9),
      },
      color2: {
        value: new THREE.Color(0x2dc152),
      },
      size: {
        value: size,
      },
      opacity: {
        value: opacity,
      },
    },
    vertexShader: `
          varying vec2 vUv;
          varying float vZ;
          uniform float size;
          void main() {
            vec4 mvPosition = modelViewMatrix * vec4(position, 1.0 );
            vUv = mvPosition.xy;
            vZ = mvPosition.z;
            gl_PointSize = size * (300. / -mvPosition.z);
            gl_Position = projectionMatrix * mvPosition;
          }
        `,
    fragmentShader: `
          uniform vec3 color1;
          uniform vec3 color2;
          uniform float opacity;
          varying vec2 vUv;
          varying float vZ;

          void main() {
            // gl_FragColor = vec4(, 1.0);
            float len = length(gl_PointCoord * 2. - 1.);
            float shape = step(len, 1.);

            float zAlpha = smoothstep(60., 35., -vZ);

            float progress = (-vUv.x + vUv.y) / 14. + .1;
            gl_FragColor = vec4(mix(color1, color2, progress), shape * opacity * zAlpha);
          }
        `,
  });

  const lineMaterial = new ShaderMaterial({
    transparent: true,
    uniforms: {
      color1: {
        value: new THREE.Color(0x1291e9),
      },
      color2: {
        value: new THREE.Color(0x2dc152),
      },
      opacity: {
        value: opacity,
      },
    },
    vertexShader: `
          varying vec2 vUv;
          varying float vZ;
          uniform float size;
          void main() {
            vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
            vUv = mvPosition.xy;
            vZ = mvPosition.z;
            gl_Position = projectionMatrix * mvPosition;
          }
        `,
    fragmentShader: `
          uniform vec3 color1;
          uniform vec3 color2;
          uniform float opacity;
          varying vec2 vUv;
          varying float vZ;

          void main() {
            // gl_FragColor = vec4(color1, 1.0);
            // float len = length(gl_PointCoord * 2. - 1.);
            // float shape = step(len, 1.);

            float zAlpha = smoothstep(62., 35., -vZ);

            float progress = (-vUv.x + vUv.y) / 14. + .1;
            gl_FragColor = vec4(mix(color1, color2, progress), opacity * zAlpha);
          }
        `,
  });

  let geometry = new THREE.IcosahedronGeometry(radius, 1);
  geometry.deleteAttribute('uv');
  geometry.deleteAttribute('normal');

  const vertices = [];
  for (let i = 0; i < geometry.attributes.position.count; i++) {
    const vertex = new THREE.Vector3();
    vertex.fromBufferAttribute(geometry.attributes.position, i);
    vertices.push(vertex);
  }

  geometry = new THREE.BufferGeometry().setFromPoints(getPoints(0.2, vertices, yDiff));

  const points = new THREE.Points(geometry, pointMaterial);
  const lines = new THREE.Line(geometry, lineMaterial);

  const group = new THREE.Group();

  group.add(points);
  group.add(lines);
  return { group, geometry, vertices };
};

export default {
  name: 'Renew',
  setup() {
    onMounted(() => {
      const container = document.getElementById('container');
      const camera = new THREE.PerspectiveCamera(
        45,
        window.innerWidth / window.innerHeight,
        1,
        1000,
      );
      camera.position.set(0, -3, 50);
      const scene = new THREE.Scene();
      const renderer = new THREE.WebGLRenderer();
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setClearColor(0x000000, 1);
      container.appendChild(renderer.domElement);
      window.addEventListener('resize', onWindowResize, false);

      function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
        animate();
      }

      const { group: R1Group, vertices: R1Vertices, geometry: R1Geometry } = getMesh(7, 1, 2, 0.3);
      const { group: R2Group, vertices: R2Vertices, geometry: R2Geometry } = getMesh(
        3.5,
        0.2,
        1.3,
        0.1,
      );

      const group = new THREE.Group();
      group.add(R1Group);
      group.add(R2Group);

      group.rotation.x += 0.5;
      scene.add(group);

      const clock = new THREE.Clock();

      let time = 0;
      function animate() {
        time += clock.getDelta();
        const progress = (time % DURATION) / DURATION;

        R1Geometry.setFromPoints(getPoints(progress, R1Vertices));
        R1Geometry.attributes.position.needsUpdate = true;

        R2Geometry.setFromPoints(getPoints(progress, R2Vertices));
        R2Geometry.attributes.position.needsUpdate = true;

        R1Group.rotation.y -= 0.015;
        R2Group.rotation.y -= 0.01;
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
      }

      clock.start();
      animate();
    });
  },
};
</script>

<style lang="stylus">
body
  background #000
</style>

<style lang="stylus" scoped>
*
  box-sizing border-box
  color #fff
  margin 0

#container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
}
</style>
