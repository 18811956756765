<template lang="pug">
.container
  canvas.canvas(ref='canvas' :style="{width: '100vw', height: '100vh'}")
</template>

<script>
import { onMounted, ref } from 'vue';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';
import * as THREE from 'three';
import spiralFrag from '../commands/particle-spiral/spiral.fs';
import spiralVert from '../commands/particle-spiral/spiral.vs';
import bgFrag from '../commands/particle-spiral/bg.fs';
import bgVert from '../commands/particle-spiral/bg.vs';

const SPIRALS = 96;
const PARTICLES_PER_SPIRAL = 96;
const PARTICLE_COUNT = SPIRALS * PARTICLES_PER_SPIRAL;
const CAMERA_POS = [-0.14, -0.01, 1.49];
const PARTICLE_ROT = [-1.15, 0.3, 0];

export default {
  name: 'ParticleSpiral',
  setup() {
    const canvas = ref(null);
    onMounted(() => {
      const { camera, scene, renderer, controls } = presetThree(canvas.value);

      const bg = initBg();
      const particleSpiral = initParticleSpiral();

      scene.add(particleSpiral.mesh, bg.mesh);

      bg.mesh.renderOrder = 0;
      particleSpiral.mesh.renderOrder = 1;
      particleSpiral.mesh.rotation.set(...PARTICLE_ROT);
      camera.position.set(...CAMERA_POS);

      const colors = {
        topLeft: '#111111',
        topRight: '#111111',
        bottomLeft: '#070643',
        bottomRight: '#070643',
      };
      const gui = new GUI();
      gui.addColor(colors, 'topLeft').onChange(() => {
        bg.mesh.material.uniforms.u_topLeft.value.set(colors.topLeft);
      });
      gui.addColor(colors, 'topRight').onChange(() => {
        bg.mesh.material.uniforms.u_topRight.value.set(colors.topRight);
      });
      gui.addColor(colors, 'bottomLeft').onChange(() => {
        bg.mesh.material.uniforms.u_bottomLeft.value.set(colors.bottomLeft);
      });
      gui.addColor(colors, 'bottomRight').onChange(() => {
        bg.mesh.material.uniforms.u_bottomRight.value.set(colors.bottomRight);
      });

      let raf;
      const anime = () => {
        raf = requestAnimationFrame(anime);
        controls.update();
        particleSpiral.update();
        renderer.render(scene, camera);
      };

      anime();
    });
    return {
      canvas,
    };
  },
};

export function initBg() {
  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute(
    'position',
    new THREE.BufferAttribute(new Float32Array([-1, -1, 0, 4, -1, 0, -1, 4, 0]), 3),
  );

  const material = new THREE.ShaderMaterial({
    uniforms: {
      u_topLeft: {
        value: new THREE.Color(0x111111),
      },
      u_topRight: {
        value: new THREE.Color(0x111111),
      },
      u_bottomRight: {
        value: new THREE.Color(0x070643),
      },
      u_bottomLeft: {
        value: new THREE.Color(0x070643),
      },
      u_aspect: {
        value: new THREE.Vector2(1, window.innerHeight / window.innerWidth),
      },
    },
    vertexShader: bgVert,
    fragmentShader: bgFrag,
  });

  return { mesh: new THREE.Mesh(geometry, material) };
}

function initParticleSpiral() {
  const material = new THREE.ShaderMaterial({
    uniforms: {
      u_time: {
        value: 0,
      },
      u_noiseTime: {
        value: 0,
      },
      u_resolution: {
        value: {
          x: window.innerWidth,
          y: window.innerHeight,
        },
      },
      u_spiral: {
        value: 1,
      },
      u_activeRatio: {
        value: 1,
      },
    },
    vertexShader: spiralVert,
    fragmentShader: spiralFrag,
    depthTest: false,
    depthWrite: false,
    blending: THREE.CustomBlending,
    blendEquation: THREE.AddEquation,
    blendSrc: THREE.OneFactor,
    blendDst: THREE.OneFactor,
    blendEquationAlpha: THREE.AddEquation,
    blendSrcAlpha: THREE.OneFactor,
    blendDstAlpha: THREE.ZeroFactor,
  });
  material.defines.SPIRALS = SPIRALS;
  material.extensions.derivatives = true;

  const position = new Float32Array(3 * PARTICLE_COUNT);
  let n = 0;
  for (let t = 0; t < PARTICLE_COUNT; t++) {
    const r = Math.random();
    const i = Math.random();
    n += 3;
    position[n + 0] = r;
    position[n + 1] = i;
  }

  const random = new Float32Array(4 * PARTICLE_COUNT);
  let s = 0;
  for (let o = 0; o < PARTICLE_COUNT; o++) {
    random[s + 0] = Math.random();
    random[s + 1] = Math.random();
    random[s + 2] = Math.random();
    random[s + 3] = Math.random();
    s += 4;
  }
  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute('position', new THREE.BufferAttribute(position, 3));
  geometry.setAttribute('a_random', new THREE.BufferAttribute(random, 4));

  const mesh = new THREE.Points(geometry, material);
  mesh.frustumCulled = false;
  mesh.renderOrder = 1;

  const r = 1;
  const i = 1;
  const e = 0.04;

  const update = () => {
    mesh.material.uniforms.u_activeRatio.value = r;
    mesh.material.uniforms.u_spiral.value = i;
    mesh.material.uniforms.u_time.value += e;
    mesh.material.uniforms.u_noiseTime.value += 5 * e * (1 - r) ** 3;
  };

  return {
    mesh,
    update,
  };
}

function presetThree(canvas) {
  const w = window.innerWidth;
  const h = window.innerHeight;

  const scene = new THREE.Scene();

  const camera = new THREE.PerspectiveCamera(60, w / h, 0.1, 50);
  scene.add(camera);

  const renderer = new THREE.WebGLRenderer({
    antialias: true,
    canvas,
    alpha: true,
  });

  renderer.toneMapping = THREE.NoToneMapping;

  const controls = new OrbitControls(camera, renderer.domElement);

  renderer.setPixelRatio(1.5);
  renderer.setSize(w, h);
  renderer.setClearColor(0x000000);

  window.addEventListener('resize', () => {
    const w = window.innerWidth;
    const h = window.innerHeight;
    camera.aspect = w / h;
    camera.updateProjectionMatrix();
    renderer.setPixelRatio(2);
    renderer.setSize(w, h);
  });

  return {
    camera,
    scene,
    renderer,
    controls,
  };
}
</script>
