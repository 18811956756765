<template lang="pug">
#container
</template>

<script>
import * as THREE from 'three';
import { onMounted } from 'vue';
import { ShaderMaterial } from 'three';

const OVAL_A = 10;
const PREC = 0.25;
const PREC_2 = 0.4;

const DURATION = 0.8;
const BASIC_POINTS = [];
const COUNT = 30;

for (let angle = -Math.PI + PREC; angle <= 0; angle += PREC) {
  BASIC_POINTS.push(new THREE.Vector3(OVAL_A * Math.cos(angle), 1.5 * OVAL_A * Math.sin(angle), 0));
}

for (let angle = -0.1; angle >= -Math.PI; angle -= PREC_2) {
  BASIC_POINTS.push(new THREE.Vector3(OVAL_A * Math.cos(angle), 0.3 * OVAL_A * Math.sin(angle), 0));
}

const mix = (a, b, p) => a * (1 - p) + b * p;

const getPoints = (progress) => {
  const points = [];
  for (let i = 0; i <= BASIC_POINTS.length; i++) {
    points.push(
      new THREE.Vector3(
        mix(
          BASIC_POINTS[i % BASIC_POINTS.length].x,
          BASIC_POINTS[(i + 1) % BASIC_POINTS.length].x,
          progress,
        ),
        mix(
          BASIC_POINTS[i % BASIC_POINTS.length].y,
          BASIC_POINTS[(i + 1) % BASIC_POINTS.length].y,
          progress,
        ),
        mix(
          BASIC_POINTS[i % BASIC_POINTS.length].z,
          BASIC_POINTS[(i + 1) % BASIC_POINTS.length].z,
          progress,
        ),
      ),
    );
  }
  return points;
};

export default {
  name: 'Renew',
  setup() {
    onMounted(() => {
      const container = document.getElementById('container');
      const camera = new THREE.PerspectiveCamera(
        45,
        window.innerWidth / window.innerHeight,
        1,
        1000,
      );
      camera.position.set(0, -5, 95);
      const scene = new THREE.Scene();
      const renderer = new THREE.WebGLRenderer();
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setClearColor(0x000000, 1);
      container.appendChild(renderer.domElement);
      window.addEventListener('resize', onWindowResize, false);

      const pointMaterial = new ShaderMaterial({
        transparent: true,
        uniforms: {
          color1: {
            value: new THREE.Color(0x1291e9),
          },
          color2: {
            value: new THREE.Color(0x2dc152),
          },
        },
        vertexShader: `
          varying vec2 vUv;
          uniform float size;
          void main() {
            vUv = position.xy;
            vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
            gl_PointSize = 2. * (300. / -mvPosition.z);
            gl_Position = projectionMatrix * mvPosition;
          }
        `,
        fragmentShader: `
          uniform vec3 color1;
          uniform vec3 color2;
          varying vec2 vUv;

          void main() {
            // gl_FragColor = vec4(, 1.0);
            float len = length(gl_PointCoord * 2. - 1.);
            float shape = step(len, 1.);

            float progress = vUv.x / 20. + .5;
            gl_FragColor = vec4(mix(color1, color2, progress), shape);
          }
        `,
      });

      let basicOvalGeometry = new THREE.BufferGeometry().setFromPoints(getPoints(0));

      const group = new THREE.Group();

      // let angle = 0;
      for (let angle = 0; angle <= Math.PI * 2; angle += (Math.PI * 2) / COUNT) {
        const oval = new THREE.Group();
        const basicOvalPoints = new THREE.Points(basicOvalGeometry, pointMaterial);
        const basicOvalLine = new THREE.Line(
          basicOvalGeometry,
          new THREE.LineBasicMaterial({ color: 0x1291e9, transparent: true, opacity: 0.5 }),
        );
        oval.add(basicOvalPoints);
        oval.add(basicOvalLine);
        oval.rotation.x += angle;
        group.add(oval);
      }

      function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
        animate();
      }

      group.rotation.y += 3.9;
      group.rotation.x += 3.9;
      scene.add(group);

      const clock = new THREE.Clock();

      let time = 0;
      function animate() {
        time += clock.getDelta();
        const progress = (time % DURATION) / DURATION;
        basicOvalGeometry.setFromPoints(getPoints(progress));
        basicOvalGeometry.attributes.position.needsUpdate = true;
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
      }

      clock.start();
      animate();
    });
  },
};
</script>

<style lang="stylus">
body
  background #000
</style>

<style lang="stylus" scoped>
*
  box-sizing border-box
  color #fff
  margin 0

#container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
}
</style>
