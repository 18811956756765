<template lang="pug">
#container
</template>

<script>
import THREE from '../../utils/three';
import { onMounted } from 'vue';

const R1 = 40;
const R2 = 20 * Math.sqrt(2);
const Y_RATIO = 0.6;
const Y_DIFF = R1 * Y_RATIO;

const getPointPosition = (index, t) => {
  const indexA = Math.floor(index / 8);
  const indexB = index % 8;
  let x, y;
  if (indexB <= 3) {
    x = R1 * Math.sin(t + (indexB * Math.PI) / 2);
    y = R1 * Math.cos(t + (indexB * Math.PI) / 2) * Y_RATIO + Y_DIFF * indexA;
  } else {
    x = R2 * Math.sin(t + (indexB * Math.PI) / 2 + Math.PI / 4);
    y = R2 * Math.cos(t + (indexB * Math.PI) / 2 + Math.PI / 4) * Y_RATIO + Y_DIFF * indexA;
  }
  return { x, y };
};

export default {
  name: 'Renew',
  setup() {
    onMounted(() => {
      const container = document.getElementById('container');

      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(
        45,
        window.innerWidth / window.innerHeight,
        1,
        1000,
      );
      scene.add(camera);

      camera.updateProjectionMatrix();
      camera.position.set(0, 0, 350);
      camera.lookAt(scene.position);

      const renderer = new THREE.WebGLRenderer();
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setClearColor(0x000000);
      renderer.sortObjects = false;
      container.appendChild(renderer.domElement);

      const loader = new THREE.TextureLoader();
      const pointTexture = loader.load('../assets/models/material/circle.png');
      const pointMaterialA = new THREE.PointsMaterial({
        color: 0x1291e9,
        size: 3.5,
        map: pointTexture,
        opacity: 1,
        transparent: true,
        alphaTest: 0.5,
      });
      const pointMaterialB = new THREE.PointsMaterial({
        color: 0x24b183,
        size: 3.5,
        map: pointTexture,
        opacity: 1,
        transparent: true,
        alphaTest: 0.5,
      });
      const pointMaterialC = new THREE.PointsMaterial({
        color: 0x2dc152,
        size: 3,
        map: pointTexture,
        opacity: 1,
        transparent: true,
        alphaTest: 0.5,
      });

      const points = [];
      const trails = [];
      const group = new THREE.Group();

      let geometry = new THREE.CircleGeometry(0.45, 1);
      geometry.deleteAttribute('uv');
      geometry.deleteAttribute('normal');

      const vertices = [];
      for (let i = 0; i < geometry.attributes.position.count; i++) {
        const vertex = new THREE.Vector3();
        vertex.fromBufferAttribute(geometry.attributes.position, i);
        vertices.push(vertex);
      }

      const trailHeadGeometry = vertices;

      for (let i = 0; i < 8 * 3; i += 1) {
        const { x, y } = getPointPosition(i, 0);
        const pointGeometry = new THREE.BufferGeometry().setFromPoints([
          new THREE.Vector3(0, 0, 0),
        ]);
        const point = new THREE.Points(pointGeometry, pointMaterialA);
        point.position.x = x;
        point.position.y = y;
        points.push(point);

        const trail = new THREE.TrailRenderer(scene, false);
        const trailMaterial = THREE.TrailRenderer.createBaseMaterial();
        trail.initialize(trailMaterial, 34, false, 0, trailHeadGeometry, point);

        if (Math.floor(i / 8) === 2) {
          trailMaterial.uniforms.headColor.value.set(0.176, 0.759, 0.322, 1);
          point.material = pointMaterialC;
        }

        if (Math.floor(i / 8) === 1) {
          trailMaterial.uniforms.headColor.value.set(0.141, 0.694, 0.514, 1);
          point.material = pointMaterialB;
        }

        if (Math.floor(i / 8) === 0) {
          trailMaterial.uniforms.headColor.value.set(0.071, 0.569, 0.914, 1);
          point.material = pointMaterialA;
        }

        trailMaterial.uniforms.tailColor.value.set(0.071, 0.569, 0.914, 1);
        trail.activate();

        trails.push(trail);
        group.add(point);
      }
      scene.add(group);

      let t = 0;

      function animate() {
        requestAnimationFrame(animate);
        t += 0.04;

        trails.forEach((trail) => {
          trail.advance();
        });

        points.forEach((point, index) => {
          const { x, y } = getPointPosition(index, t);
          point.position.x = x;
          point.position.y = y;
        });

        renderer.render(scene, camera);
      }

      animate();
    });
  },
};
</script>

<style lang="stylus">
body
  background #000
</style>

<style lang="stylus" scoped>
*
  box-sizing border-box
  color #fff
  margin 0

#container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
}
</style>
